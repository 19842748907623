(function() {
	/**
	 * @exports ASM.controllers.home
	 * @requires HBS
	 */
	let module = {};

	module.init = () => {
		module.setupTabs();
		module.setActiveTabState();
		module.initializeCarousels();
		module.setupMobileTabs();
		module.initializeGalleryModal();
	};

	module.initializeGalleryModal = () => {
		let galleryModalLinks = document.querySelectorAll('.js-gallery-modal');
		let modalContainer = document.querySelector('.modal__container');
		let modalImg = document.querySelector('.js-gallery-image');
		let modalTitle = document.querySelector('.js-gallery-title');

		galleryModalLinks.forEach((link) => {
			let title = link.querySelector('.gallery__item-title') ? link.querySelector('.gallery__item-title').innerHTML : '';
			let imgSrc = link.getAttribute('href');

			link.addEventListener('click', (event) => {
				console.log('clickEventWorking');
				//if this is a swatch make the modal window background color match the swatch;
				let swatchDiv = link.querySelector('.gallery__swatch');
				let swatchStyle;
				let swatchHex;
				if(swatchDiv) {
					console.log('isaswatchdiv');
					swatchStyle = swatchDiv.getAttribute('style');
					swatchHex = ((swatchStyle.indexOf('#') !== -1) ? `#${swatchStyle.split('#')[1]}` : `#ffffff`);
					modalContainer.setAttribute('style',`background-color: ${swatchHex}`);
					modalContainer.classList.add('is-swatch');
					console.log(swatchStyle);
					console.log(swatchHex);
				}
				event.preventDefault();
				modalImg.setAttribute('src', imgSrc);
				modalTitle.innerHTML = title;

				MicroModal.show('product-gallery-modal',{
					onClose: function(){
						modalImg.setAttribute('src', '');
						modalTitle.innerHTML = '';
						if(modalContainer.getAttribute('style')) {
							modalContainer.removeAttribute('style');
							modalContainer.classList.remove('is-swatch');
						}
					}
				});
			});
		});
	};

	module.initializeCarousels = () => {
		let swiperInstances = document.querySelectorAll('.js-gallery-carousel');

		if(swiperInstances.length) {
			swiperInstances.forEach((instance, index) => {
				instance.classList.add(`js-gallery-carousel-${index}`);
				let nextButton = instance.querySelector('.swiper-button-next');
				let prevButton = instance.querySelector('.swiper-button-prev');
				nextButton.classList.add(`swiper-button-next-${index}`);
				prevButton.classList.add(`swiper-button-prev-${index}`);
				let galleryCarousel = new Swiper(`.js-gallery-carousel-${index}`, {
					slidesPerView: 4,
					speed: 400,
					centeredSlides: false,
					loop: false,

					breakpoints: {
						// when window width is <= 640
						640: {
							slidesPerView: 4,
							slidesPerGroup: 4
						}
					},

					// Navigation arrows
					navigation: {
						nextEl: `.swiper-button-next-${index}`,
						prevEl: `.swiper-button-prev-${index}`,
					}
				});

				galleryCarousel.init(
					setTimeout(() => {
						window.dispatchEvent(new Event("resize"))
					}, 200)
				);
			});
		}

	};

	module.setupMobileTabs = () => {
		let mobileDropdownTrigger = document.querySelector('.js-tab-mobile-trigger');
		let navList = document.querySelector('.js-tab-mobile-dropdown');

		let closeOnClickOutside = (event) => {
			let target = event.target;

			if (mobileDropdownTrigger && (mobileDropdownTrigger !== target && navList !== target) && (!mobileDropdownTrigger.contains(target) && !navList.contains(target))) {
				navList.classList.remove('state-visible');
			}
		};

		document.addEventListener('click', closeOnClickOutside);

		if(mobileDropdownTrigger) {
			mobileDropdownTrigger.addEventListener('click', (event) => {
				event.preventDefault();
				if(navList.classList.contains('state-visible')) {
					navList.classList.remove('state-visible');
				} else {
					navList.classList.add('state-visible');
				}
			});
		}

		enquire.register("screen and (max-width:991px)", {
			match : function() {
				let tabs = document.querySelectorAll('.pd-tabs__item');
				let tabLinkHtml = '';
				tabs.forEach((tab) => {
					if(tab.classList.contains('state-active')) {
						let tabLink =  tab.querySelector('.js-tab-trigger');
						tabLinkHtml = module.stripHtml(tabLink.innerHTML);
					}
				});
				mobileDropdownTrigger.innerText = tabLinkHtml;
			},
			unmatch : function() {
				if(navList.classList.contains('state-visible')) {
					navList.classList.remove('state-visible');
				}
			}
		});
	};

	module.setActiveTabState = () => {
		let tabItems = document.querySelectorAll('.pd-tabs__item');
		let sections = document.querySelectorAll('.js-section');
		let mobileDropdownTrigger = document.querySelector('.js-tab-mobile-trigger');

		tabItems.forEach((tab, index) => {
			if(index === 0 && !tab.classList.contains('state-active')) {
				tab.classList.add('state-active');
				let activeElement = tab.querySelector('.js-tab-trigger');
				mobileDropdownTrigger.innerText = module.stripHtml(activeElement.innerHTML);
			}

			if(tab.classList.contains('state-active')) {
				let activeElement = tab.querySelector('.js-tab-trigger');
				mobileDropdownTrigger.innerText = module.stripHtml(activeElement.innerHTML);
			}
		});

		sections.forEach((section, index) => {
			if(index === 0 && !section.classList.contains('state-active')) {
				section.classList.add('state-active');
			}
		});
	};

	module.stripHtml = (html) => {
		// Create a new div element
		var temporalDivElement = document.createElement("div");
		// Set the HTML content with the providen
		temporalDivElement.innerHTML = html;
		// Retrieve the text property of the element (cross-browser support)
		return temporalDivElement.textContent || temporalDivElement.innerText || "";
	};

	module.setupTabs = () => {
		let tabs = document.querySelectorAll('.js-tab-trigger');
		let sections = document.querySelectorAll('.js-section');

		console.log('getting here');
		//this if statement will prevent the code below from running if there are no tab elements
		if(!tabs.length) {
			return;
		}

		tabs.forEach((tab) => {
			let parent = tab.parentElement; //this is the <li> element
			let section = tab.getAttribute('data-section-id');
			let mobileDropdownTrigger = document.querySelector('.js-tab-mobile-trigger');
			let menu = document.querySelector('.js-tab-mobile-dropdown');

			//setup tab click listener
			tab.addEventListener('click', (event) => {
				event.preventDefault();

				//loop through and remove all the state-active classes on the <li> elements
				tabs.forEach((tabElement) => {
					let parent = tabElement.parentElement;
					parent.classList.remove('state-active');
				});

				//loop through and remove all the state-active classes on the <section> element
				sections.forEach((section) => {
					section.classList.remove('state-active');
				});

				//re-add state-active class tab only;
				parent.classList.add('state-active');

				//add state-active class on section to show
				let sectionToLoad = document.getElementById(section);
				sectionToLoad.classList.add('state-active');

				mobileDropdownTrigger.innerText = module.stripHtml(tab.innerHTML);
				if(menu.classList.contains('state-visible')) {
					menu.classList.remove('state-visible');
				}
				module.initializeCarousels();
			});
		});
	};

	HBS.namespace('ASM.controllers.productDetail', module);
}());